export class Cart {
  constructor(cartForm) {
    this.cartForm = cartForm;
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.cartForm.addEventListener('change', (e) => {
      if (e.target.classList.contains('js-cart-quantity')) {
        this.cartForm.submit();
      }
    });

    this.cartForm.addEventListener('click', (e) => {
      if (e.target.classList.contains('js-cart-delete')) {
        const lineItem = e.target.closest('.js-cart-item');
        const lineItemQuantityField = lineItem.querySelector('.js-cart-quantity');
        lineItemQuantityField.value = 0;
        this.cartForm.submit();
      }
    });
  }
}
