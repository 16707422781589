export class PaymentForm {
  constructor(paymentForm) {
    this.paymentForm = paymentForm;
    this.paymentMethodsRadiosArr = [
      ...this.paymentForm.querySelectorAll('.js-payment-method-radio'),
    ];
    this.creditCardsRadiosArr = [...this.paymentForm.querySelectorAll('.credit-card-radio')];
    this.paymentMethodsAccordion = this.paymentForm.querySelector('.js-payment-methods-accordion');
    this.submitBtn = this.paymentForm.querySelector('.js-payment-submit-btn');

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.paymentMethodsAccordion.addEventListener('show.bs.collapse', (e) => {
      this.paymentMethodsRadiosArr.forEach((radio) => {
        const paymentMethodRadio = radio;
        paymentMethodRadio.checked = false;
      });
      this.creditCardsRadiosArr.forEach((radio) => {
        const creditCardRadio = radio;
        creditCardRadio.checked = false;
      });

      const collapseId = e.target.id;
      const paymentMethodRadio = document.querySelector(
        `[data-bs-target="#${collapseId}"] input[type="radio"]`
      );
      paymentMethodRadio.checked = true;
      if (paymentMethodRadio.id !== 'use_existing_card_yes') {
        document.querySelector('#use_existing_card_no').checked = true;
      }
    });

    this.paymentForm.addEventListener('submit', (e) => {
      if (!this.validatePaymentMethod()) {
        e.preventDefault();
        return false;
      }
      this.setSubmitButtonLoadingState();

      return true;
    });

    document.addEventListener('showPaymentError', () => {
      this.resetSubmitButton();
    });

    if (this.creditCardsRadiosArr.length) {
      this.creditCardsRadiosArr.forEach((radio) => {
        radio.addEventListener('change', (e) => {
          this.hidePaymentMethodError(e.target.closest('.js-payment-method-wrapper'));
        });
      });
    }
  }

  validatePaymentMethod() {
    const selectedPaymentMethodRadio = this.paymentMethodsRadiosArr.find((radio) => radio.checked);

    if (!selectedPaymentMethodRadio) {
      return false;
    }

    const paymentMethodWrapper = selectedPaymentMethodRadio.closest('.js-payment-method-wrapper');

    if (selectedPaymentMethodRadio.id === 'use_existing_card_yes') {
      const existingCreditCardsRadios = paymentMethodWrapper.querySelectorAll('.credit-card-radio');
      const anyCreditCardSelected = [...existingCreditCardsRadios].some((radio) => radio.checked);

      if (anyCreditCardSelected) {
        this.hidePaymentMethodError(paymentMethodWrapper);
        return true;
      }

      this.showPaymentMethodError(paymentMethodWrapper);
    }

    if (selectedPaymentMethodRadio.dataset.paymentMethodName === 'Stripe') {
      return true;
    }

    // TODO: provide code for validating paypal (if needed? how do I know paypal payment went through?)
    return false;
  }

  setSubmitButtonLoadingState() {
    this.submitBtn.innerHTML = 'Processing <i class="fa-solid fa-spinner fa-spin ms-1"></i>';
    this.submitBtn.disabled = true;
  }

  resetSubmitButton() {
    this.submitBtn.innerHTML = 'Save and continue <i class="fa-solid fa-angle-right fa-sm"></i>';
    this.submitBtn.disabled = false;
  }

  showPaymentMethodError(wrapper) {
    wrapper.querySelector('.js-payment-method-error').classList.remove('d-none');
  }

  hidePaymentMethodError(wrapper) {
    wrapper.querySelector('.js-payment-method-error').classList.add('d-none');
  }
}
