import $ from 'jquery';
import { Tooltip as BsTooltip } from 'bootstrap';

export default class Util {
  static initAllTooltips = () => {
    const tooltips = [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map((tooltip) => {
      return new BsTooltip(tooltip, {
        delay: { show: 150, hide: 50 },
      });
    });

    return tooltips;
  };

  static scrollToElement = (element, scrollOffset = 0, duration = 500) => {
    if (!(element instanceof HTMLElement)) {
      throw new Error('scrollToElement(): Invalid HTMLElement');
    }

    const offsetTop = $(element).offset().top;
    $('html, body').animate(
      {
        scrollTop: offsetTop - scrollOffset,
      },
      duration
    );
  };
}
