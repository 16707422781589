import $ from 'jquery';

export class TwSearch {
  constructor(twSearchWrapper) {
    this.tyres = window.tyreData;
    this.vehicles = window.vehicleData;
    this.container = twSearchWrapper;
    this.searchType = this.container.dataset.searchType;
    this.fields = this.container.querySelectorAll('.js-tw-search-field');
    this.submitButton = this.container.querySelector('.js-tw-search-cta');

    this.width = this.container.querySelector('.js-tw-search-width');
    this.profile = this.container.querySelector('.js-tw-search-profile');
    this.diameter = this.container.querySelector('.js-tw-search-diameter');

    this.make = this.container.querySelector('.js-tw-search-make');
    this.model = this.container.querySelector('.js-tw-search-model');

    this.loadPrevious(); // maintain selected form elements
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.container.addEventListener('change', (e) => {
      const { target } = e;
      if (target.classList.contains('js-tw-search-field')) {
        this.reset(target);

        if (e.target.value === '') {
          return;
        }

        const nextDropdown = this.getNextDropdown(e.target);
        if (nextDropdown) {
          this.addSpinner(nextDropdown);
          this.load(nextDropdown);
          this.removeSpinner(nextDropdown);
        }
      }
    });

    this.submitButton.addEventListener('click', (e) => {
      e.preventDefault();

      let searchUrl = null;
      if (this.searchType == 'tyres') {
        searchUrl =
          `${window.location.origin}/t/tyres?utf8=%E2%9C%93&per_page=` +
          `&search%5Btyre_width%5D%5B%5D=${this.width.value}&search%5Btyre_profile%5D%5B%5D=${this.profile.value}&search%5Btyre_diameter%5D%5B%5D=${this.diameter.value}#searchResults`;
      } else if (this.searchType == 'wheels') {
        searchUrl = `${window.location.origin}/t/compatible-vehicles/${this.make.value
          .replace(/\s+/g, '-')
          .toLowerCase()}/${this.model.value
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/(^-|-$)/g, '')}#searchResults`;
      }

      if (window.location.href != searchUrl) {
        e.target.disabled = true;
        e.target.innerHTML = 'Searching… <i class="fa-solid fa-spinner fa-spin"></i>';
        window.location.href = searchUrl;
      }
    });
  }

  getNextDropdown(el) {
    let nextDropdown = null;

    switch (el.dataset.fieldType) {
      case 'width':
        nextDropdown = this.profile;
        break;
      case 'profile':
        nextDropdown = this.diameter;
        break;
      case 'make':
        nextDropdown = this.model;
        break;
      default:
        nextDropdown = null;
    }

    return nextDropdown;
  }

  loadPrevious() {
    const urlParams = new URLSearchParams(window.location.href.replace(/#.*$/, ''));

    if (urlParams.getAll('search[tyre_width][]').length && this.profile) {
      this.load(this.profile, () => {
        this.removeSpinner(this.profile);
        if (urlParams.getAll('search[tyre_profile][]').length) {
          this.profile.value = urlParams.get('search[tyre_profile][]');

          if (urlParams.getAll('search[tyre_diameter][]').length) {
            this.load(this.diameter, () => {
              this.removeSpinner(this.diameter);
              this.diameter.value = urlParams.get('search[tyre_diameter][]');
              this.reset(this.diameter);
            });
          }
        }
      });
    } else if (window.location.href.indexOf('compatible-vehicles') > -1 && this.make) {
      const makeModel = window.location.pathname.split('/').slice(3);
      let make = makeModel[0];
      const model = makeModel[1];

      if (make == 'land-rover') {
        make = 'Land Rover';
      } else if (make == 'mercedes-benz') {
        make = 'Mercedes-Benz';
      } else {
        make = make.charAt(0).toUpperCase() + make.slice(1);
      }

      this.make.value = make;

      this.load(this.model, () => {
        this.removeSpinner(this.model);
        const selectOptions = this.model.querySelectorAll('option');
        selectOptions.forEach((option) => {
          if (
            option.value
              .replace(/\s+/g, '-')
              .toLowerCase()
              .replace(/[^a-zA-Z0-9-–]/g, '')
              .replace('–', '-')
              .replace('--', '-') == model
          ) {
            option.setAttribute('selected', 'selected');
          }
        });

        this.reset(this.model);
      });
    }
  }

  reset(el) {
    switch (el.dataset.fieldType) {
      case 'width':
        var fields = [this.profile, this.diameter];
        break;
      case 'profile':
        var fields = [this.diameter];
        break;
      case 'make':
        var fields = [this.model];
        break;
    }

    const cta = el.closest('.js-tw-search').querySelector('.js-tw-search-cta');
    if (typeof fields !== 'undefined') {
      fields.forEach((field) => {
        $(field).find('option').not(':first').remove();
        $(field).prop('disabled', true);
        $(field).parent().addClass('disabled');
      });

      cta.disabled = true;
    } else {
      cta.disabled = el.value === '';
    }
  }

  load(el, callback) {
    let loadData;

    if (this.searchType === 'tyres') {
      const tyreWidth = this.width.value;
      if (tyreWidth) {
        loadData = Object.keys(this.tyres[tyreWidth]);

        if (el.dataset.fieldType === 'diameter') {
          const tyreProfile = this.profile.value;
          loadData = this.tyres[tyreWidth][tyreProfile];
        }
      }
    } else if (this.searchType === 'wheels') {
      const vehicleMake = this.make.value;
      if (vehicleMake) {
        loadData = this.vehicles[vehicleMake];
      }
    }

    if (loadData) {
      const sortedLoadData = loadData.sort();
      sortedLoadData.forEach((val) => {
        const option = document.createElement('option');
        option.value = val;
        option.text = val;
        el.add(option);
      });

      if (callback) {
        callback();
      }
    }
  }

  getSpinnerHTML() {
    return `<div class="tw-search-loading">
              <i class="fa-solid fa-spinner fa-spin me-1"></i> Loading…
            </div>`;
  }

  addSpinner(el) {
    el.disabled = true;
    const fieldWrapper = el.parentNode;
    const spinnerHTML = this.getSpinnerHTML();
    $(fieldWrapper).prepend(spinnerHTML);
  }

  removeSpinner(el) {
    el.disabled = false;
    const fieldWrapper = el.parentNode;
    const spinnerNode = fieldWrapper.querySelector('.tw-search-loading');
    if (spinnerNode) {
      fieldWrapper.removeChild(spinnerNode);
    }
  }
}
