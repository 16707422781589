(function( $ ) {

  // Window observer

  // DOM Observers

  $(function() {
    if ($('#jsProductSort').length) {
      TyresDirect.ProductSort.init();
    }
  });

  // Subscribers

  // Object/Namespace

  TyresDirect.ProductSort = {
    init: function() {
      TyresDirect.ProductSort.setupDOMObservers();
    },

    setupDOMObservers: function() {
      $('#jsProductSort').change(function(event) {
        var sorts = $(this).find(':selected').data('sorts');

        if ('URLSearchParams' in window) {
          var searchParams = new URLSearchParams(window.location.search);
          searchParams.set("search[sorts]", sorts);
          window.location.search = searchParams.toString();
        }
      });
    }
  }
})( jQuery );
