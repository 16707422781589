import Swiper, { Navigation } from 'swiper';

const adjustOpacityForInvisibleSlides = (event) => {
  const visibleSlides = event.slides.filter((slide) =>
    slide.classList.contains('swiper-slide-visible')
  );

  visibleSlides.forEach((el, i, arr) => {
    const slide = el;
    const firstOrLastSlide = i === 0 || i === arr.length - 1;
    slide.style.opacity = firstOrLastSlide ? 0.3 : 1;
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const bestSellersCarousel = document.querySelector('.js-best-sellers');
  if (bestSellersCarousel) {
    const swiper = new Swiper(bestSellersCarousel, {
      modules: [Navigation],
      slidesPerView: 1.6,
      spaceBetween: 20,
      centeredSlides: true,
      watchSlidesProgress: true,
      loop: true,
      allowTouchMove: false,
      navigation: {
        nextEl: '.js-swiper-button-next',
        prevEl: '.js-swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2.2,
        },
        992: {
          slidesPerView: 3.4,
        },
        1080: {
          slidesPerView: 3.4,
          spaceBetween: 30,
        },
        1280: {
          slidesPerView: 3.6,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 5.5,
          spaceBetween: 30,
        },
        2160: {
          slidesPerView: 5.8,
          spaceBetween: 30,
        },
      },
      on: {
        init(e) {
          adjustOpacityForInvisibleSlides(e);
        },
        slideChange(e) {
          adjustOpacityForInvisibleSlides(e);
        },
        breakpoint(e) {
          adjustOpacityForInvisibleSlides(e);
        },
      },
    });
  }

  const relatedProductsCarousel = document.querySelector('.js-related-products');
  if (relatedProductsCarousel) {
    const swiper = new Swiper(relatedProductsCarousel, {
      modules: [Navigation],
      slidesPerView: 1.6,
      spaceBetween: 20,
      centeredSlides: true,
      watchSlidesProgress: true,
      allowTouchMove: false,
      loop: true,
      watchOverflow: true,
      navigation: {
        nextEl: '.js-swiper-button-next',
        prevEl: '.js-swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2.2,
        },
        992: {
          slidesPerView: 3.4,
        },
        1080: {
          slidesPerView: 3.4,
          spaceBetween: 30,
        },
        1280: {
          slidesPerView: 3.6,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 5.5,
          spaceBetween: 30,
        },
        2160: {
          slidesPerView: 5.8,
          spaceBetween: 30,
        },
      },
      on: {
        init(e) {
          adjustOpacityForInvisibleSlides(e);
        },
        slideChange(e) {
          adjustOpacityForInvisibleSlides(e);
        },
        breakpoint(e) {
          adjustOpacityForInvisibleSlides(e);
        },
      },
    });
    // setTimeout(() => {
    //   swiper.destroy();
    // }, 1000);
  }
});
