// Entry point for the build script in your package.json

// Bootstrap
import * as bootstrap from 'bootstrap';

// Stripe
import './stripe-styles';

// FontAwesome
import '@fortawesome/fontawesome-free/js/all';

// import utilities
import Util from './util';

// Components
import './components/tw-search';
import './components/filter';
import './components/product-sort';
import './components/hero';
import './components/nav-dropdown';
import './components/address';
import './components/lazy-load';
import './components/autosize';
import './components/matchHeight';
import { Cart } from './components/cart';
import { TwSearch } from './components/tw-search-new';
import './components/cookie-consent';
import './components/swiper-sliders';
import { PaymentForm } from './components/payment-form';

require('@rails/activestorage').start();

document.addEventListener('DOMContentLoaded', () => {
  // initialize tooltips
  const tooltips = Util.initAllTooltips();

  // init scrolling to element
  if (document.querySelectorAll('.js-scrollto').length) {
    [...document.querySelectorAll('.js-scrollto')].forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const clickedAnchor = e.currentTarget;
        const targetIdSelector = clickedAnchor.getAttribute('href');
        const elementToScrollTo = document.querySelector(targetIdSelector);
        Util.scrollToElement(elementToScrollTo, 140);
      });
    });
  }

  const cartForm = document.querySelector('.js-cart');
  if (cartForm) {
    const cart = new Cart(cartForm);
  }

  const paymentForm = document.querySelector('.js-checkout-payment');
  if (paymentForm) {
    const payment = new PaymentForm(paymentForm);
  }

  const twSearchWrappers = document.querySelectorAll('.js-tw-search');
  if (twSearchWrappers.length) {
    twSearchWrappers.forEach((twSearchWrapper) => new TwSearch(twSearchWrapper));
  }
});
