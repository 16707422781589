import Readmore from 'readmore-js';

(function( $ ) {

  // Window observer

  // DOM Observers

  $(function() {
    if ($('.js-filter').length) {
      window.TyresDirect.Filter.init();
    }
  });

  // Subscribers

  // Object/Namespace

  window.TyresDirect.Filter = {
    init: function() {
      var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      var filterChoicesHeightCollapsed;
      var filterChoicesheightMargin;

      if (width < 768) {
        filterChoicesHeightCollapsed = 190
        filterChoicesHeightMargin = 45
      } else if (width >= 768 && width < 992) {
        filterChoicesHeightCollapsed = 190
        filterChoicesHeightMargin = 45
      } else {
        filterChoicesHeightCollapsed = 122
        filterChoicesHeightMargin = 28
      }

       // initialize filters readmore
       $('.filter-values').each(function() {
        if ($(this).find('input[type="checkbox"]:checked').length == 0) {
          new Readmore(this, {
            collapsedHeight: filterChoicesHeightCollapsed,
            heightMargin: filterChoicesHeightMargin,
            speed: 300,
            moreLink: '<a href="#" class="text-decoration-underline chevron-down-after filter-accordion-link">View more</a>',
            lessLink: '<a href="#" class="text-decoration-underline chevron-up-after filter-accordion-link">View less</a>',
          })
        }
       });

      window.TyresDirect.Filter.setupDOMObservers();
    },

    setupDOMObservers: function() {
      $('.js-filter input[type="checkbox"]').change(function() {
        $('.js-filter-btn').attr('disabled', false);
      })

      $('.js-filter-btn').click(function(e) {
        $('#searchResults').fadeTo('fast', 0.1);
        $('.filter-spinner-wrapper').fadeIn('fast');
      });

      if ($('.js-filter-clear').length) {
        $('.js-filter-clear').on('click', function() {
          $(this).siblings('.filter-values').find('input[type="checkbox"]').prop('checked', false);
          $(this).closest('form').submit();
        });
      }
    }
  }
})( jQuery );
