window.SolidusStripe.Elements.prototype.baseStyle = function () {
  return {
    base: {
      color: '#121415',
      backgroundColor: '#fff',
      fontFamily: 'Lato, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      lineHeight: '1.5',
      fontWeight: '400',
    },
    invalid: {
      iconColor: '#f22613',
      color: '#f22613',
    },
  };
};

window.SolidusStripe.Elements.prototype.cardNumberElementOptions = function () {
  return {
    style: this.baseStyle(),
    showIcon: true,
  };
};

window.SolidusStripe.Elements.prototype.showError = function (error) {
  const message = error.message || error;
  this.errorElement.text(message).show();

  const showPaymentErrorEvent = new CustomEvent('showPaymentError');
  document.dispatchEvent(showPaymentErrorEvent);
};
