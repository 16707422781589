(function ($) {
  // Window observer

  // DOM Observers

  $(function () {
    if ($('.js-checkout-address').length) {
      TyresDirect.Checkout.Address.init();
    }
  });

  // Subscribers

  // Object/Namespace

  TyresDirect.Checkout.Address = {
    countyClass: '.js-checkout-address-county',
    useBillingClass: '.js-checkout-address-use-billing',

    init() {
      // Hidden by default to support browsers with javascript disabled
      $('.js-checkout-address-fields').show();

      TyresDirect.Checkout.Address.updateCountySelect();
      TyresDirect.Checkout.Address.toggleShippingVisibility();
      TyresDirect.Checkout.Address.setupDOMObservers();
    },

    setupDOMObservers() {
      $(this.useBillingClass).change(function () {
        TyresDirect.Checkout.Address.toggleShippingVisibility();
      });
    },

    updateCountySelect() {
      const englishCounties = [
        'Bedfordshire',
        'Berkshire',
        'Bristol',
        'Buckinghamshire',
        'Cambridgeshire',
        'Cheshire',
        'City of London',
        'Cornwall',
        'County Durham',
        'Cumbria',
        'Derbyshire',
        'Devon',
        'Dorset',
        'East Riding of Yorkshire',
        'East Sussex',
        'Essex',
        'Gloucestershire',
        'Greater London',
        'Greater Manchester',
        'Hampshire',
        'Herefordshire',
        'Hertfordshire',
        'Isle of Wight',
        'Isles of Scilly',
        'Kent',
        'Lancashire',
        'Leicestershire',
        'Lincolnshire',
        'Merseyside',
        'Norfolk',
        'North Yorkshire',
        'Northamptonshire',
        'Northumberland',
        'Nottinghamshire',
        'Oxfordshire',
        'Rutland',
        'Shropshire',
        'Somerset',
        'South Yorkshire',
        'Staffordshire',
        'Suffolk',
        'Surrey',
        'Tyne and Wear',
        'Warwickshire',
        'West Midlands',
        'West Sussex',
        'West Yorkshire',
        'Wiltshire',
        'Worcestershire',
      ];
      const scottishCounties = [
        'Aberdeen City',
        'Aberdeenshire',
        'Angus',
        'Argyll and Bute',
        'Clackmannanshire',
        'Dumfries and Galloway',
        'Dundee City',
        'East Ayrshire',
        'East Dunbartonshire',
        'East Lothian',
        'East Renfrewshire',
        'City of Edinburgh',
        'Falkirk',
        'Fife',
        'Glasgow City',
        'Highland',
        'Inverclyde',
        'Midlothian',
        'Moray',
        'North Ayrshire',
        'North Lanarkshire',
        'Orkney Islands',
        'Perth and Kinross',
        'Renfrewshire',
        'Scottish Borders',
        'Shetland Islands',
        'South Ayrshire',
        'South Lanarkshire',
        'Stirling',
        'West Dunbartonshire',
        'West Lothian',
        'Western Isles; Na h-Eileanan Siar',
      ];
      const welshCounties = [
        'Blaenau Gwent',
        'Bridgend; Pen-y-bont ar Ogwr',
        'Caerphilly; Caerffili',
        'Cardiff; Caerdydd',
        'Carmarthenshire; Sir Gaerfyrddin',
        'Ceredigion; Sir Ceredigion',
        'Conwy',
        'Denbighshire; Sir Ddinbych',
        'Flintshire; Sir y Fflint',
        'Gwynedd',
        'Isle of Anglesey; Ynys Môn',
        'Merthyr Tydfil; Merthyr Tudful',
        'Monmouthshire; Sir Fynwy',
        'Neath Port Talbot; Castell-nedd Port Talbot',
        'Newport; Casnewydd',
        'Pembrokeshire; Sir Benfro',
        'Powys',
        'Rhondda Cynon Taf',
        'Swansea; Abertawe',
        'Torfaen; Tor-faen',
        'Vale of Glamorgan; Bro Morgannwg',
        'Wrexham; Wrecsam',
      ];

      $(this.countyClass).each(function () {
        const $optGroupEngland = $('<optgroup label="England"></optgroup>');
        const $optGroupScotland = $('<optgroup label="Scotland"></optgroup>');
        const $optGroupWales = $('<optgroup label="Wales"></optgroup>');

        $(this).append($optGroupEngland);
        $(this).append($optGroupScotland);
        $(this).append($optGroupWales);

        $('> option', $(this)).each(function () {
          const countyName = $(this).html();

          if (englishCounties.includes(countyName)) {
            $(this).appendTo($optGroupEngland);
          }

          if (scottishCounties.includes(countyName)) {
            $(this).appendTo($optGroupScotland);
          }

          if (welshCounties.includes(countyName)) {
            $(this).appendTo($optGroupWales);
          }
        });
      });
    },

    toggleShippingVisibility() {
      if ($(this.useBillingClass).is(':checked')) {
        $('#shipping .inner').hide();
        $('#shipping .inner input, #shipping .inner select').prop('disabled', true);
      } else {
        $('#shipping .inner').show();
        $('#shipping .inner input, #shipping .inner select').prop('disabled', false);
      }
    },
  };
})(jQuery);
