require('cookieconsent');

setTimeout(() => {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#f9f9f9',
        text: '#121415',
      },
    },
    position: 'bottom-left',
    content: {
      href: '/cookies',
    },
  });
}, 1000);
