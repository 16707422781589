(function( $ ) {

  // Window observer

  // DOM Observers

  $(function() {
    TyresDirect.NavDropdown.init();
  });

  // Subscribers

  // Object/Namespace

  TyresDirect.NavDropdown = {
    init: function() {
      TyresDirect.NavDropdown.setupDOMObservers();
    },

    setupDOMObservers: function() {
      $('.js-nav-dropdown-link').click(function(event) {
        event.preventDefault();
        event.stopPropagation();

        TyresDirect.NavDropdown.hide();
        TyresDirect.NavDropdown.show($(this));
      });

      $('body').on('click touchend', function(event) {
        if ($(event.target).closest('.nav-header-dropdown').length == 0) {
          TyresDirect.NavDropdown.hide();
        }
      });
    },

    show: function(navLink) {
      navLink.parent('.nav-item').addClass('nav-item-current');
      $('#' + navLink.data('target')).show();
    },

    hide: function() {
      $('.js-nav-dropdown-link').parent('.nav-item').removeClass('nav-item-current');
      $('.nav-header-dropdown').hide();
    }
  }
})( jQuery );
