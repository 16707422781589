import Readmore from 'readmore-js';

document.addEventListener('DOMContentLoaded', () => {
  const heroReadMore = new Readmore('.js-hero-description', {
    heightMargin: 36,
    speed: 300,
    moreLink: '<a href="#" class="text-decoration-underline chevron-down-after">Read more</a>',
    lessLink: '<a href="#" class="text-decoration-underline chevron-up-after">Read less</a>',
  });
});
