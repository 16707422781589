(function( $ ) {

  // Window observer

  // DOM Observers

  // Subscribers

  // Object/Namespace

  window.TyresDirect.TWSearch = {
    init: function(tyreData, vehicleData) {
      window.TyresDirect.TWSearch.config = {
        tyres: tyreData,
        vehicles: vehicleData,
        container: '.js-tw-search',
        field: '.js-tw-search-field',
        cta: '.js-tw-search-cta',

        // Tyres
        width: '.js-tw-search-width',
        profile: '.js-tw-search-profile',
        diameter: '.js-tw-search-diameter',

        // Wheels
        make: '.js-tw-search-make',
        model: '.js-tw-search-model',
      };

      window.TyresDirect.TWSearch.resetForm(); // unsure if needed anymore
      window.TyresDirect.TWSearch.loadPrevious(); // maintain selected form elements
      window.TyresDirect.TWSearch.setupDOMObservers();
    },

    resetForm: function() {
      $([$(window.TyresDirect.TWSearch.config.width), $(window.TyresDirect.TWSearch.config.profile), $(window.TyresDirect.TWSearch.config.diameter), $(window.TyresDirect.TWSearch.config.make), $(window.TyresDirect.TWSearch.config.model)]).each(function () {
        $(this).val($(this).find('option[selected]').val());
      });
    },

    loadPrevious: function() {
      var url = new URLSearchParams(window.location.href.replace(/#.*$/,''));

      if (url.getAll('search[tyre_width][]').length == 1) {
        window.TyresDirect.TWSearch.load($(window.TyresDirect.TWSearch.config.profile), function() {
          window.TyresDirect.TWSearch.removeSpinner($(window.TyresDirect.TWSearch.config.profile));
          if (url.getAll('search[tyre_profile][]').length == 1) {
            $(window.TyresDirect.TWSearch.config.profile).val(url.get('search[tyre_profile][]'));

            if (url.getAll('search[tyre_diameter][]').length == 1) {
              window.TyresDirect.TWSearch.load($(window.TyresDirect.TWSearch.config.diameter), function() {
                window.TyresDirect.TWSearch.removeSpinner($(window.TyresDirect.TWSearch.config.diameter));
                $(window.TyresDirect.TWSearch.config.diameter).val(url.get('search[tyre_diameter][]'));
                window.TyresDirect.TWSearch.reset($(window.TyresDirect.TWSearch.config.diameter));
              });
            }
          }
        });
      } else if (window.location.href.indexOf("compatible-vehicles") > -1) {
        var makeModel = window.location.pathname.split( '/' ).slice(3);
        var make = makeModel[0];
        var model = makeModel[1];

        if (make == "land-rover") {
          make = "Land Rover";
        } else if (make == "mercedes-benz") {
          make = "Mercedes-Benz";
        } else {
          make = make.charAt(0).toUpperCase() + make.slice(1);
        }

        $(window.TyresDirect.TWSearch.config.make).val(make);
        window.TyresDirect.TWSearch.load($(window.TyresDirect.TWSearch.config.model), function() {
          window.TyresDirect.TWSearch.removeSpinner($(window.TyresDirect.TWSearch.config.model));
          $(window.TyresDirect.TWSearch.config.model).children('option').each(function() {
            if ($(this).val().replace(/\s+/g, '-').toLowerCase().replace(/[^a-zA-Z0-9-–]/g, '').replace('–', '-').replace('--', '-') == model) {
              $(this).attr('selected', 'selected');
            }
          });
          window.TyresDirect.TWSearch.reset($(window.TyresDirect.TWSearch.config.model));
        });
      }
    },

    setupDOMObservers: function() {
      $(window.TyresDirect.TWSearch.config.field).change(function(event) {
        window.TyresDirect.TWSearch.reset($(this));
        var $closestContainer = $(this).closest(window.TyresDirect.TWSearch.config.container);

        if (!$(this).val() == '') {
          switch($(this).data('fieldType')) {
            case 'width':
              var nextDropdown = $closestContainer.find(window.TyresDirect.TWSearch.config.profile);
              break;
            case 'profile':
              var nextDropdown = $closestContainer.find(window.TyresDirect.TWSearch.config.diameter);
              break;
            case 'make':
              var nextDropdown = $closestContainer.find(window.TyresDirect.TWSearch.config.model);
              break;
          }

          if (typeof nextDropdown !== 'undefined') {
            window.TyresDirect.TWSearch.load(nextDropdown, function() {
              window.TyresDirect.TWSearch.removeSpinner(nextDropdown);
            });
          }
        }
      });

      $(window.TyresDirect.TWSearch.config.cta).click(function(event) {
        event.preventDefault();
        var searchType = $(this).data('searchType');
        var $closestContainer = $(this).closest(window.TyresDirect.TWSearch.config.container);

        if (searchType == "tyres") {
          searchUrl = window.location.origin + '/t/tyres?utf8=%E2%9C%93&per_page=' + '&search%5Btyre_width%5D%5B%5D=' + $closestContainer.find(window.TyresDirect.TWSearch.config.width).val() + '&search%5Btyre_profile%5D%5B%5D=' + $closestContainer.find(window.TyresDirect.TWSearch.config.profile).val() + '&search%5Btyre_diameter%5D%5B%5D=' + $closestContainer.find(window.TyresDirect.TWSearch.config.diameter).val() + '#searchResults';
        } else if (searchType == "wheels") {
          searchUrl = window.location.origin + '/t/compatible-vehicles/' + $closestContainer.find(window.TyresDirect.TWSearch.config.make).val().replace(/\s+/g, '-').toLowerCase() + '/' + $closestContainer.find(window.TyresDirect.TWSearch.config.model).val().toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'') + '#searchResults';
        }

        if (window.location.href != searchUrl) {
          $(this).attr('disabled', true).html('Searching… <i class="fa-solid fa-spinner fa-spin"></i>');
          window.location.href = searchUrl;
        }
      });
    },

    reset: function(el) {
      var cta = el.closest('form').find(window.TyresDirect.TWSearch.config.cta);

      switch(el.data('fieldType')) {
        case 'width':
          var fields = [el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.profile), el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.diameter)];
          break;
        case 'profile':
          var fields = [el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.diameter)];
          break;
        case 'make':
          var fields = [el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.model)];
          break;
      }

      if (typeof fields !== 'undefined') {
        $(fields).each(function() {
          $(this).find('option').not(':first').remove();
          $(this).prop('disabled', true);
          $(this).parent().addClass('disabled');
        });
        cta.prop('disabled', true);
      } else {
        cta.prop('disabled', (el.val() == '' ? true : false));
      }
    },

    load: function(el, callback) {
      var type = el.data('searchType');
      var loadData;
      window.TyresDirect.TWSearch.addSpinner(el);

      if (type == 'tyres') {
        var tyreWidth = el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.width)[0].value;
        loadData = Object.keys(window.TyresDirect.TWSearch.config.tyres[tyreWidth]);

        if (el.data('fieldType') == 'diameter') {
          var tyreProfile = el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.profile)[0].value;
          loadData = window.TyresDirect.TWSearch.config.tyres[tyreWidth][tyreProfile];
        }
      } else if (type == 'wheels') {
        var vehicleMake = el.closest(window.TyresDirect.TWSearch.config.container).find(window.TyresDirect.TWSearch.config.make)[0].value;
        loadData = window.TyresDirect.TWSearch.config.vehicles[vehicleMake];
      }

      $.each(loadData.sort(), function(i, v) {
        el.append($('<option>', {
          value: v,
          text: v
        }));
      });

      callback();
    },

    addSpinner: function(el) {
      el.parent().removeClass('disabled');
      el.parent().prepend('<div class="tw-search-loading"><i class="fa-solid fa-spinner fa-spin me-1"></i> Loading…</div>');
    },

    removeSpinner: function(el) {
      el.prop('disabled', false);
      el.prev().remove();
    }
  }
})( jQuery );
